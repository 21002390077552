import { extend } from 'vee-validate';
import { email, numeric, min, max } from 'vee-validate/dist/rules';

//const eanRegex: RegExp = /^(\d{8}|\d{12,14})$/;

// export const isValidEan = function(ean: string) {
//     try {
//         if (!eanRegex.test(ean)) return false; // early exit if no regex match
//         ean = ean.padStart(14, '0'); // pad ean to 14 digits
//         return ean
//             .split('') // convert string to array
//             .map((s, i) => Number(s) * ((i % 2 === 0) ? 3 : 1)) // multiply all digits by either 3 or 1
//             .reduce((sum,i) => sum + i) // get the sum
//             % 10 === 0 // the code is valid if the sum is divisible by 10
//     } catch {
//         return false;
//     }
// }

// Cykelpartner validates EAN against gln on customer profile
export const isValidEan = function(ean: string) {
  try {
    const gln = document.getElementById('gln') as HTMLInputElement;
    return gln.value === ean;
  } catch {
      return false;
  }
}

export const isValidName = function(name: string) {
  try {
    const trimmed = name.trim();
    if (!trimmed.includes(' ')) return false;
    const hasCharacters = (str: string) => str.length > 0;
    return trimmed.split(' ').every(hasCharacters);
  } catch {
    return false;
  }
}

export const setupValidationRules = function() {
    extend('email', email);
    extend('numeric', numeric);
    extend('min', min);
    extend('max', max);
    extend('true', {
        validate (value) {
          return {
            required: true,
            valid: value === true
          };
        },
        computesRequired: true
    });
    extend('ean', {
        validate (value) {
          return {
            required: true,
            valid: isValidEan(value)
          };
        },
        computesRequired: true
    });
    extend('multiword', {
      validate (value) {
        return {
          required: true,
          valid: isValidName(value)
        };
      },
      computesRequired: true
    });
    extend('phone', {
      validate (value) {
        return {
          required: true,
          valid: new RegExp('^([+](\\d{1,3})\\s?)?((\\(\\d{3,5}\\)|\\d{3,5})(\\s)?)\\d{3,8}$').test(value)
        };
      },
      computesRequired: true
    });
    extend('required', {
        validate (value) {
          return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1
          };
        },
        computesRequired: true
    });
}
