
import { Component, Prop, Vue } from "vue-property-decorator";
import { WishList, WishListItem } from '../../models/WishList';
import { ProductSearchResultProduct } from '../../models/Product';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import LoadingModule from '../../store/modules/LoadingModule';
import UserModule from '../../store/modules/UserModule';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';
import WishListService from '../../services/WishListService';

const wishListService: WishListService = new WishListService();

@Component({
    name: "DetailsAddToWishList",
    components: {

    }
})
export default class DetailsAddToWishList extends Vue {

    @Prop({ type: String, required: false, default: '' }) private size: string;

    get isLoading(): boolean {
        return LoadingModule.IS_LOADING;
    }

    get isSignedIn(): boolean {
        return UserModule.IS_SIGNED_IN;
    }

    get product(): ProductSearchResultProduct {
        return ProductDetailsModule.PRODUCT;
    }

    get sku(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return ProductDetailsModule.SELECTED_VARIANT?.sku;
        }
        return this.product?.id;
    }

    get colli(): number {
        if (ProductDetailsModule.VARIANTS?.length > 0) {
            return this.wishlistQuantity = ProductDetailsModule.SELECTED_VARIANT?.colli;
        }
        return this.wishlistQuantity = this.product?.colli;
    }

    get displayWishListButton(): boolean {
        return CbxVariablesModule.VARIABLES.enableWishList;
    }

    private wishlists: WishList[] = [];
    private currentwishlist: WishList = null;
    private currentwishlistindex: number = 0;

    private wishListModalOpen: boolean = false;
    private addWishList: boolean = false;
    private wishlistLabel: string = null;
    private wishlistQuantity: number = 1;

    private showSuccessMessage: boolean = false;

    private async wishListModalShow(reload: boolean = true) {
        if (reload)
            await this.getWishLists();
        this.wishListModalOpen = true;
    }

    private wishListModalHide() {
        // reset
        this.wishlistLabel = null;
        this.wishlistQuantity = this.colli;

        this.showSuccessMessage = false;

        this.wishListModalOpen = false;
    }

    private async validate(e: any) {
        e.preventDefault();

        if (this.addWishList && !this.wishlistLabel)
            return;

        if (this.wishlistQuantity < 1)
            return;

        await this.addToWishList();
    }

    private async getWishLists() {
        if (this.isSignedIn) {
            const l = await wishListService.getWishLists();
            this.wishlists = l.wishlists;

            if (l.wishlists.length > 0) {
                await this.changeCurrentWishList(0);
            } else {
                this.addWishList = true;
            }
        }
        else {
            this.wishlists = [];
            this.currentwishlist = null;
        }
    }

    private async changeCurrentWishList(index: number) {
        this.currentwishlist = this.wishlists[index];
        this.currentwishlistindex = index;
    }

    private async addToWishList() {

        const payload: WishListItem = {
            label: this.addWishList ? this.wishlistLabel : this.currentwishlist.label,
            productid: this.sku,
            quantity: this.wishlistQuantity
        };

        // respect colli 
        payload.quantity = Math.ceil(payload.quantity / this.colli) * this.colli;

        if (await wishListService.addWishListItem(payload)) {
            this.showSuccessMessage = true;
            this.reset();
        }
    }

    private reset() {
        this.addWishList = false;
        this.wishlistLabel = '';
    }

    // @Watch('isSignedIn', {immediate: true})
    // private onIsSignedInChange() {
    //     this.getWishLists()
    // }
}
