
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import MagicSearchResult, { FilterModelHit } from '@/models/MagicSearch';
import ProductService from '@/services/ProductService';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import LoadMore from '@/components/load-more/LoadMore.vue';
import { ProductSearchResultProduct } from '@/models/Product';

const ps: ProductService = new ProductService();

@Component({
    name: 'CPMobileHeaderSearchOverlayModal',
    components: {
        LoadMore,
    },
})
export default class CPMobileHeaderSearchOverlayModal extends Vue {
    @Prop({ type: Boolean, required: false, default: false }) private displayed: boolean;

    private result: MagicSearchResult = null;
    private isFetching: boolean = false;
    private keyword: string = '';
    private displayOverlay: boolean = false;
    private activeTab: number = 0;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get popularQuery() {
        return {
            Fields: [
                {
                    Field: 'data.categoryRelationsV2',
                    FieldType: 'string',
                    Values: [CbxVariablesModule.VARIABLES.rootCategoryId],
                },
            ],
            Limit: 12,
            SortField: 'data.attributedata.popularity',
            SortDirection: '-1',
        };
    }

    get products(): FilterModelHit[] {
        return this.result?.products ?? [];
    }

    get categories(): FilterModelHit[] {
        return this.result?.categories ?? [];
    }

    get cmspages(): FilterModelHit[] {
        return this.result?.cmspages ?? [];
    }

    private displayprice(product: ProductSearchResultProduct): string {
        if (product?.price) {
            return (product.price.showpricesincvat ? product.price.priceincvat : product.price.priceexvat)
            + ' ' + product.price.currency
        }
        return '';
    }

    private async search() {
        this.isFetching = true;
        this.result = await ps.magicSearch({
            query: this.keyword,
            limit: 24,
        });
        this.isFetching = false;
    }

    private reset() {
        this.keyword = '';
        this.result = null;
    }

    private setKeyword(input: string) {
        this.keyword = input;
        this.search();
    }

    private async showMore(doctype: string) {
        this.isFetching = true;
        let container = null;
        let offset = 0;
        switch (doctype) {
            case 'PRODUCT':
                container = this.result.products;
                break;
            case 'CATEGORY':
                container = this.result.categories;
                break;
            case 'CMSPAGE':
                container = this.result.cmspages;
                break;
        }
        offset = container.length;

        const response: MagicSearchResult = await ps.magicSearch({
            query: this.keyword,
            doctype: doctype,
            limit: 24,
            offset,
        });

        switch (doctype) {
            case 'PRODUCT':
                this.result.products.push(...response.products);
                break;
            case 'CATEGORY':
                this.result.categories.push(...response.categories);
                break;
            case 'CMSPAGE':
                this.result.cmspages.push(...response.cmspages);
                break;
        }
        this.isFetching = false;
    }

    @Debounce(250)
    private async handleKeywordInput() {
        this.search();
    }

    @Watch('displayed')
    private onDisplayedChange(n: boolean) {
        if (n) {
            this.reset();
            this.$nextTick(() => (this.$refs.searchInput as any).focus());
        }
    }
}
