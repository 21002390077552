
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DisplayPriceInfo } from '../../models/Product';
import DetailsDuties from './DetailsDuties.vue';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';
import { CbxVariablesModule } from '../../store/modules/CbxVariablesModule';
import { AnydaySettings } from '@/models/CbxVariables';
import CheckoutModule from '@/store/modules/CheckoutModule';

@Component({
    name: 'DetailsPrice',
    components: {
        DetailsDuties,
    },
})
export default class DetailsPrice extends Vue {
    @Prop({ type: String, required: true }) private stsText: string;
    @Prop({ type: String, required: true }) private rrpText: string;
    @Prop({ type: String, required: true }) private exvatText: string;
    @Prop({ type: String, required: true }) private incvatText: string;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get loading(): boolean {
        return ProductDetailsModule.IS_LOADING;
    }

    get displayPriceInfo(): DisplayPriceInfo {
        if (ProductDetailsModule.VARIANTS?.length > 0)
            return ProductDetailsModule.SELECTED_VARIANT?.price;
        else 
            return ProductDetailsModule.PRODUCT?.price;
    }

    get price(): string {
        if (this.displayPriceInfo.showpricesincvat)
            return this.displayPriceInfo.priceincvat;
        else
            return this.displayPriceInfo.priceexvat;
    }

    get vatPrice(): string {
        if (!this.displayPriceInfo.showpricesincvat)
            return this.displayPriceInfo.priceincvat;
        else
            return this.displayPriceInfo.priceexvat;
    }    

    get vatDesc() {
        if (!this.displayPriceInfo.showpricesincvat)
            return this.incvatText;
        else
            return this.exvatText;
    }

    get unit(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0)
            return ProductDetailsModule.SELECTED_VARIANT?.unit;
        else 
            return ProductDetailsModule.PRODUCT?.unit;
    }

    get showAnydayWidget(): boolean {
        return (this.anydaySettings.minamount <= 0 ||  this.anydaySettings.minamount <= this.displayPriceInfo.priceincvatasnumeric)
            && (this.anydaySettings.maxamount <= 0 || this.anydaySettings.maxamount >= this.displayPriceInfo.priceincvatasnumeric);
    }

    get anydaySettings(): AnydaySettings {
        return CbxVariablesModule.VARIABLES.anydaySettings;
    }

    get showExpirationDate() : boolean {
        if (this.displayPriceInfo.discountexpiration == null)
            return false;
        
        const discountExpiraton = new Date(this.displayPriceInfo.discountexpiration);
        let threeDaysAhead = new Date();
        threeDaysAhead.setDate(threeDaysAhead.getDate() + 3);

        return discountExpiraton <= threeDaysAhead;
    }

    get bonusTerms(): string {
        return CheckoutModule.BONUS_TERMS;
    }

    private formatDiscountExpirationDate() : string {
        const date = new Date(this.displayPriceInfo.discountexpiration);
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString((window as any).CbxCulture, { month: 'long' });
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        let retval = `${day}. ${month} ${year}`;
        if (date.getDate() === new Date().getDate())
            retval += ` ${this.$t('pricing.time', [ 'kl.' ])} ${hours}:${minutes}`;

        return retval;
    }

    private async created() {
        CheckoutModule.GET_BONUS_TERMS();
    }
}
